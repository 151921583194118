import React from "react"

export default function NewsTabIframe(props) {
  const { width, height, src, frameborder, allow, allowfullscreen } = props
  return (
    <div className="NewsTabIframe">
      <iframe
        width={width}
        height={height}
        src={src}
        frameborder={frameborder}
        title="Titulo"
        allow={allow}
        allowfullscreen={allowfullscreen}
      ></iframe>
    </div>
  )
}
