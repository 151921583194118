export const activityContent1 = {
  content: `<div justify="" style="text-align: justify;" text-align:=""><a _blank="" href="/docs/reglas_generales_examenes.pdf" target="_blank"><strong>(Ver Reglas Generales)</strong></a><br>
    <br>
    <br>
    La <strong>certificación EFPA&nbsp;<em>European Financial Planner</em>&nbsp;(EFP)</strong>&nbsp;certifica la idoneidad profesional para ejercer tareas de planificación financiera personal integral de alto nivel de complejidad y volumen, en banca privada, family offices y en general en servicios de consultoría para patrimonios elevados. Para acceder a esta certificación es imprescindible contar con la certificación EFA en vigor, por lo que sólo los asociados de EFPA España pueden acceder a la misma.<br>
    <br>
    <strong>El examen se estructura en dos partes (ambas deben aprobarse):</strong><br>
    <br>
    <strong>1. Una primera parte</strong>, consiste en un test de cincuenta preguntas&nbsp;de opción múltiple sobre aquellas materias recogidas en el Programa de EFP.&nbsp;<br>
    Para obtener la calificación de Apto será requisito el haber respondido correctamente al menos el 70% del examen (35 preguntas). Las respuestas incorrectas o en blanco no restan puntos.<br>
    <br>
    <strong>Duración de la primera parte: 2 horas.</strong><br>
    &nbsp;<br>
    <strong>2. Una segunda parte</strong>, en la que el candidato deberá demostrar los conocimientos y la capacidad de análisis y aplicación sobre todas aquellas materias que aparecen en el Programa de Certificación EFP más las incluidas en los anexos del Programa (puntos 3 y 4) y consistirá en la resolución de uno o varios casos prácticos globales sobre Planificación Financiera, donde el candidato deberá demostrar sus habilidades y conocimientos en relación a los siguientes aspectos:</div>
    <ul style="padding-left: 10px; margin-top: 15px">
	<li justify="" style="text-align: justify;" text-align:="">Recopilación de los datos del cliente.</li>
	<li justify="" style="text-align: justify;" text-align:="">Identificar sus necesidades y posibles soluciones.</li>
	<li justify="" style="text-align: justify;" text-align:="">Hacer análisis y recomendaciones.</li>
	<li justify="" style="text-align: justify;" text-align:="">Presentación del Plan financiero al cliente.</li>
</ul>
<div justify="" style="text-align: justify;" text-align:=""><br>
<strong>Duración de la segunda parte: 4 horas.</strong><br>
<br>
<br>
&nbsp;</div>`,
}

export const activityContent3 = {
  content: `<div justify="">
  <div justify="" style="text-align: justify;" text-align:="">La 3ª convocatoria dentro del año 2021, para la realización del examen para obtener la <strong>certificación&nbsp;<a _blank="" href="https://www.efpa.es/examenes-certificacion-efp" target="_blank"><em>European Financial Planner</em></a>&nbsp;(EFP)</strong>&nbsp;se celebrará el <span class="aviso">12 de noviembre</span><br>
  <br>
  <strong>Horario: De 11:30h a 13:30h y de 15h a 19h</strong></div>
  
  <ul>
    <li justify="" style="text-align: justify;" text-align:="">Acudir al lugar exacto a las 11h.</li>
  </ul>
  
  <div justify="" style="text-align: justify;" text-align:=""><span class="aviso">IMPORTANTE:</span>&nbsp;<a href="https://www.efpa.es/actualidad/noticias-efpa/comunicado-novedades-fiscales-para-el-modulo-de-fiscalidad-de-las-diferentes-certificaciones-oficiales-de-efpa-espana" target="_blank">Consulta las novedades fiscales para el Módulo de Fiscalidad</a><br>
  <br>
  <br>
  <br>
  En fechas próximas a la del examen se informará sobre los&nbsp;lugares&nbsp;donde se realizará el examen.<br>
  &nbsp;</div>
  
  <p class="subtitle" style="text-align: justify;"><a href="http://www.efpa.es/como-inscribirme-examenes-efpa" target="_blank">&#8203;¿Cómo inscribirme al examen para obtener la certificación EFPA&nbsp;<em>European Financial Planner</em>&nbsp;(EFP)?</a></p>
  
  <div justify="" style="text-align: justify;" text-align:=""><a _blank="" href="/docs/reglas_generales_examenes.pdf" target="_blank"><strong>(Ver Reglas Generales)</strong></a><br>
  <br>
  <br>
  La <strong>certificación EFPA&nbsp;<em>European Financial Planner</em>&nbsp;(EFP)</strong>&nbsp;certifica la idoneidad profesional para ejercer tareas de planificación financiera personal integral de alto nivel de complejidad y volumen, en banca privada, family offices y en general en servicios de consultoría para patrimonios elevados. Para acceder a esta certificación es imprescindible contar con la certificación EFA en vigor, por lo que sólo los asociados de EFPA España pueden acceder a la misma.<br>
  <br>
  <strong>El examen se estructura en dos partes (ambas deben aprobarse):</strong><br>
  <br>
  <strong>1. Una primera parte</strong>, consiste en un test de cincuenta preguntas&nbsp;de opción múltiple sobre aquellas materias recogidas en el Programa de EFP.&nbsp;<br>
  Para obtener la calificación de Apto será requisito el haber respondido correctamente al menos el 70% del examen (35 preguntas). Las respuestas incorrectas o en blanco no restan puntos.<br>
  <br>
  <strong>Duración de la primera parte: 2 horas.</strong><br>
  &nbsp;<br>
  <strong>2. Una segunda parte</strong>, en la que el candidato deberá demostrar los conocimientos y la capacidad de análisis y aplicación sobre todas aquellas materias que aparecen en el Programa de Certificación EFP más las incluidas en los anexos del Programa (puntos 3 y 4) y consistirá en la resolución de uno o varios casos prácticos globales sobre Planificación Financiera, donde el candidato deberá demostrar sus habilidades y conocimientos en relación a los siguientes aspectos:</div>
  
  <ul>
    <li justify="" style="text-align: justify;" text-align:="">Recopilación de los datos del cliente.</li>
    <li justify="" style="text-align: justify;" text-align:="">Identificar sus necesidades y posibles soluciones.</li>
    <li justify="" style="text-align: justify;" text-align:="">Hacer análisis y recomendaciones.</li>
    <li justify="" style="text-align: justify;" text-align:="">Presentación del Plan financiero al cliente.</li>
  </ul>
  
  <div justify="" style="text-align: justify;" text-align:=""><br>
  <strong>Duración de la segunda parte: 4 horas.</strong><br>
  <br>
  <br>
  &nbsp;</div>
  </div>`,
}
