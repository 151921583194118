import React from "react"
import { Link } from "gatsby"
import ImgWithLightBox from "../ImgWithLightBox"

export default function NewsTabImage(props) {
  const { list, renderField = "list" } = props
  const renderGallery = () => {
    if (!list) {
      return (
        <Link to="/">
          <img src="/imgs/news-logo.jpg" alt="default" />
        </Link>
      )
    }

    return (
      list && (
        <ImgWithLightBox
          images={list.map(item => ({
            src: item[renderField],
            alt: item.title,
            className: "NewsTabImage-img",
            thumb: item.thumb || "/imgs/news-logo.jpg",
            ...item,
          }))}
          btnClassName="NewsTabImage-btn"
          multiple
        />
      )
    )
  }

  return (
    <div className="NewsTab-borderTop">
      <span className="NewsTab-title">Imágenes:</span>
      <div className="NewsTab-row d-flex flex-wrap">{renderGallery()}</div>
    </div>
  )
}
