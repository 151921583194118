export const content1 = [
  {
    title: `Esta iniciativa, con motivo de la situación derivada por el COVID-19, ha sido una vía más para que los asesores financieros cumplan con la formación continua exigida por MiFID. `,
    text: `Durante las últimas doce semanas, más de 15.000 miembros certificados por EFPA España ha seguido este convenido sumando una hora de formación a su periodo actual de recertificación EIA, EIP, EFA y EFP tras la superación de un test de evaluación de conocimientos vinculado a cada newsletter.`,
  },
  {
    title: `Formación financiera de calidad`,
    text: `El regreso paulatino a la normalidad nos han llevado a poner fin a este acuerdo con tan buenos resultados, pero, no obstante, los miembros de EFPA siguen teniendo en FundsPeople otras vías con las que llevar al día su formación:`,
  },
]

export const content2 = {
  title: "Título bloque 2",
  text: [
    `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  ],
}

export const listData = [
  {
    content: "Superar el test de la revista mensual",
  },
  {
    content: `
    Acceder a la plataforma E-Learning: ver vídeo de Santiago Satrústegui, Presidente de EFPA España`,
  },
  {
    content: "Webinars",
  },
]

export const content3 = {
  title: "",
  text: [
    `
    En sus RRSS, somosarenet, ha compartido el caso de éxito de EFPA España apuntando que “tras 15 años trabajando mano a mano con la asociación, creemos firmemente en la formación online, que no solo promueve una mayor flexibilidad de horarios y localizaciones, sino que también favorece el aprendizaje con las metodologías y guías adecuadas.”`,
    `Gracias por apoyar #EFPAyomeformoencasa. `,
  ],
}

export const newsTab3 = {
  content: `<div style="text-align: justify;">Este acuerdo se convierte en <strong>un paso más para apoyar la formación continuada de los más de 35.000 miembros certificados</strong> de EFPA España y la recertificación de sus horas, ante el estado de alarma derivado de la emergencia sanitaria, que ha provocado el aplazamiento o la cancelación de multitud de eventos y cursos formativos en el sector financiero.<br>
    <br>
    El presidente de EFPA España, <a href="https://www.linkedin.com/in/santiago-satr%C3%BAstegui-67b76a5/?originalSubdomain=es" target="_blank"><strong>Santiago Satrústegui</strong></a>, señala que “<strong>este acuerdo con una de las publicaciones más importantes del sector financiero, confirma la apuesta de EFPA España por fomentar la formación continua de todos los profesionales de la industria, no solo para cumplir con la regulación comunitaria, sino porque consideramos que la formación continua es la mejor vía para ofrecer un servicio de calidad, lo que redunda en un mejor rendimiento de sus carteras</strong>”.<br>
    <br>
    Por su parte, <a href="https://www.linkedin.com/in/alicia-miguel-serrano-33a6231a/" target="_blank"><strong>Alicia Miguel</strong></a>, redactora jefe de <a href="https://www.fundssociety.com/es" target="_blank"><strong>Funds Society</strong></a>, comenta que "<strong>Para Funds Society es un honor poder colaborar con EFPA España en la formación de profesionales del mundo de la inversión, que son nuestros lectores y la industria que sustenta nuestras informaciones. La formación no solo es esencial bajo el prisma de regulaciones como MiFID, sino que, más allá de ser una obligación, es un deber de este sector para poder contribuir a crear un sistema financiero estable y generar confianza</strong>". En este sentido, la revista trimestral de Funds Society contribuirá, con sus contenidos, a crear esa formación, y también lo harán los webinars y eventos que Funds Society organizará en los próximos meses para hablar de economía e inversión.<br><br>
    &nbsp;
    <p class="subtitle">EFPA España, apoyando la formación</p>
    Además, este acuerdo contempla que los miembros certificados de EFPA España puedan seguir los <strong>eventos organizados</strong> por <strong>Funds Society</strong> y <a href="https://www.abc.es/" target="_blank"><strong>ABC</strong></a>, en la que los <strong>expertos de la industria de los fondos de inversión y el asesoramiento financiero debatirán acerca de la situación de los mercados y las perspectivas del mundo post COVID-19.</strong><br>
    &nbsp;
    <p class="subtitle"><br>
    EN PRENSA</p>
    <a href="https://www.fundssociety.com/es/noticias/private-banking/efpa-espana-y-funds-society-alcanzan-un-acuerdo-de-colaboracion-para-apoyar-la-recertificacion-de-sus-asociados-y-lectores" target="_blank"><strong>FundsSociety</strong></a></div>`,
}

export const activities1 = {
  title: "",
  text: [
    `
    <div justify="">
    <div justify="" style="text-align: justify;" text-align:="">La 1ª convocatoria dentro del año 2021, para la realización del examen para obtener la <strong>certificación&nbsp;<a _blank="" href="https://www.efpa.es/examenes-certificacion-efp" target="_blank"><em>European Financial Planner</em></a>&nbsp;(EFP)</strong>&nbsp;se celebrará el <span class="aviso">19 de febrero.</span><br>
    <br>
    <strong>Horario: De 11:30h a 13:30h y de 15h a 19h</strong></div>
    
    <ul>
      <li justify="" style="text-align: justify;" text-align:="">Acudir al lugar exacto a las 11h.</li>
    </ul>
    
    <div justify="" style="text-align: justify;" text-align:=""><span class="aviso">IMPORTANTE:</span>&nbsp;<a href="https://www.efpa.es/actualidad/noticias-efpa/comunicado-novedades-fiscales-para-el-modulo-de-fiscalidad-de-las-diferentes-certificaciones-oficiales-de-efpa-espana" target="_blank">Consulta las novedades fiscales para el Módulo de Fiscalidad</a><br>
    <br>
    <br>
    <br>
    En fechas próximas a la del examen se informará sobre los&nbsp;lugares&nbsp;donde se realizará el examen.<br>
    &nbsp;</div> <br>
    
    <p class="subtitle" style="text-align: justify;"><a href="http://www.efpa.es/como-inscribirme-examenes-efpa" target="_blank">&#8203;<strong>¿Cómo inscribirme al examen para obtener la certificación EFPA&nbsp;<em>European Financial Planner</em>&nbsp;(EFP)?</strong></a></p>

    </div>
    `,
  ],
}
