import React, { Fragment } from "react"
import News from "../News"

export default function NewsTabItem(props) {
  const { news, title = "Otras noticias:" } = props
  const renderNews = () => {
    return (
      news &&
      news.map(item => {
        return (
          <News
            className="col-12 col-sm-6"
            image={item?.image || "/imgs/news-logo.jpg"}
            date={item?.date}
            text={item?.title}
            link={`/servicios-y-actividades/${item?.category?.pk}/${item?.category?.slug}/${item?.id}/${item?.slug}`}
          />
        )
      })
    )
  }
  return (
    <Fragment>
      <span className="ActivityTab-newsRelated">{title}</span>
      <div className="row">{renderNews()}</div>
    </Fragment>
  )
}
