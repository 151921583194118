import React, { Fragment } from "react"
import List from "../List"
import parse from "react-html-parser"

export default function NewsTabContent(props) {
  const {
    className,
    content,
    image,
    listData,
    listType,
    reverse,
    useList,
  } = props

  const renderContent = () => {
    if (!reverse) {
      return (
        <div className="NewsTabContent-wrapper">
          <div className="NewsTabContent-left">{renderInfo()}</div>
          <div className="NewsTabContent-right">
            <img className="NewsTabContent-image" src={image} alt="noticia" />
          </div>
        </div>
      )
    }

    return (
      <Fragment>
        <span className="NewsTabContent-title">{content.title}</span>
        <div className="NewsTabContent-wrapper reverse">
          <div className="NewsTabContent-left">{renderInfo()}</div>
          <div className="NewsTabContent-right">
            <img className="NewsTabContent-image" src={image} alt="noticia" />
          </div>
        </div>
      </Fragment>
    )
  }

  const renderInfo = () => {
    if (!reverse) {
      return (
        content &&
        content.map(item => {
          return (
            <Fragment>
              <span className="NewsTabContent-title">{item.title}</span>
              <span className="NewsTabContent-text">{item.text}</span>
            </Fragment>
          )
        })
      )
    }

    return (
      content.text &&
      content.text.map(item => {
        return <div className="NewsTabContent-text">{parse(item)}</div>
      })
    )
  }

  const renderList = () => {
    if (!useList) {
      return <Fragment></Fragment>
    }
    return (
      <List className="NewsTabContent-list" items={listData} type={listType} />
    )
  }

  return (
    <div className={`NewsTabContent ${className}`}>
      {renderContent()}
      {renderList()}
    </div>
  )
}
