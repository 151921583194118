import React from "react"
import SocialButton from "../SocialButton"

export default function NewsTabSocial() {
  return (
    <div className="GalleryFile-social mt-3">
      <SocialButton
        icon="fab fa-facebook-f"
        backgroundColor="#42519d"
        link="/"
        className="w-100 mr-1"
      />
      <SocialButton
        icon="fa-brands fa-x-twitter"
        backgroundColor="#000000"
        link="/"
        className="w-100 ml-1"
      />
    </div>
  )
}
